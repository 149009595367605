// import * as googleTTS from 'google-tts-api';

import { StorageRounded, VolumeUp } from "@mui/icons-material";
import { Box, Button, Container, MenuItem, TextField, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { BoxFC, BoxFR } from "components/BoxCustom";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import notificationSound from 'resource/sound/doorbell-075.wav'
import noti1 from 'resource/sound/noti1-075.wav'
import { columns } from "./columns";
import dayjs from "dayjs";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import DailyJobDialogV2 from "../DailyJob/DailyJobDialogV2";
// import CheckboxFormControl from "components/CheckboxFormControl";

const synth = window.speechSynthesis;
const utterThis = new SpeechSynthesisUtterance("ส่ง หมายเลขตู้ งาน LLIT");
utterThis.pitch = 1;
utterThis.rate = 1
const contNoSound = new Audio(notificationSound)
contNoSound.volume = 0.5
const locSound = new Audio(noti1)
locSound.volume = 0.5

let lastSelectedTHVoiceIndex = 0
let selectedJobOrdId = 0

const NotificationCenter = () => {

  const [ws, setWs] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [selectedTHVoiceIndex, setSelectedTHVoiceIndex] = useState(localStorage.getItem("voiceIndex") || 0)
  const [dialogOpen, setDialogOpen] = useState(false)

  const voiceTH = useMemo(() => {
    return synth.getVoices().filter(voice => voice.lang === 'th-TH')
  }, [])
  // const [playLocationSound, setPlayLocationSound] = useState(true)
  // const [playJobOrderSound, setPlayJobOrderSound] = useState(true)

  const wsState = useMemo(() => ws?.readyState === WebSocket.OPEN ? "open" : "close", [ws])
  const { msData } = useContext(GlobalStateContext);

  const readText = useCallback(async (text, voiceIndex) => {
    speak(text, voiceTH[voiceIndex])
  }, [voiceTH])

  const reConnectWebSocket = useCallback((voiceIndex) => {
    console.log("ws?.readyState::", ws?.readyState);
    let base = window.location.origin.replace("https", "wss").replace("http", "ws");
    if (base === "ws://localhost:3000") {
      base = "wss://dev.emily.bittosen.com"
    }
    if (ws?.readyState === WebSocket.OPEN) {
      ws.close()
    }

    const websocket = new WebSocket(base);

    websocket.onopen = () => {
      console.log('WebSocket connection opened');
      setWs(websocket)
    };

    websocket.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      console.log('Message from server: ', data);
      if (data.type === "JOB_ORDER_FILE") {
        const msg = data.data
        const eventStr = `ส่งรูป ${msg.JobOrdFileTypNm} งาน ${msg.JobSNm}`
        setDataTable((o) => [{
          id: o.length + 1,
          No: o.length + 1,
          DrvNNm: msg.DrvNNm,
          JobOrdId: msg.JobOrdId,
          DteTm: dayjs(),
          Event: eventStr
        }, ...o]);
        await playAudio(contNoSound)
        await readText(`${msg.DrvNNm}${eventStr}`, voiceIndex)

      } else if (data.type === "LOCATION") {
        const dataArray = data.data

        const result = []
        for (const data of dataArray) {
          let mode = data.Mode
          let poiid = null
          if (["ARV", "ARV_HOME"].includes(data.Mode)) {
            poiid = data.POIId
          } else if (data.Mode === "LEV") {
            poiid = data.LastQueryPOIId
          }
          if (mode) {
            result.push({
              DrvNNm: data.DrvNNm,
              LicNm: data.LicNm,
              DteTm: dayjs(),
              JobOrdId: data.JobOrdId,
              Event: `${mode === "ARV" ? "ไปถึง" : mode === "ARV_HOME"? "มาถึง": "ออกจาก"}${msData.poiObj[poiid]}`
            })
          }
        }
        console.log("result::", result)

        if (result.length > 0) {
          setDataTable((o) => [
            ...result.map((item, index) => ({
              id: o.length + index + 1,
              No: o.length + index + 1,
              ...item
            }))
            , ...o
          ]);
          // if (playLocationSound) {
          await playAudio(locSound)
          for (const item of result) {
            await readText(`${item.DrvNNm}${item.Event}`, voiceIndex)
          }
          // }
        }
      }
    };

    websocket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    websocket.onerror = (error) => {
      console.error('WebSocket error: ', error);
    };
  }, [ws, readText, msData.poiObj])


  const connectWebSocket = useCallback((voiceIndex) => () => {
    reConnectWebSocket(voiceIndex)
  }, [reConnectWebSocket])

  const testThaiSound = useCallback(async () => {
    console.log("voiceTH", voiceTH)
    
    await playAudio(contNoSound)
    speak("ทดสอบ สมชายออกจาก CCIS5", voiceTH[selectedTHVoiceIndex])
  }, [selectedTHVoiceIndex, voiceTH])

  const handleVoiceChange = useCallback((e) => {
    const index = e.target.value
    if (index >= 0) {
      localStorage.setItem("voiceIndex", index)
      setSelectedTHVoiceIndex(index)
      lastSelectedTHVoiceIndex = index
      reConnectWebSocket(index)
    }
  }, [reConnectWebSocket])

  const handleJobOrdIdClick = useCallback((jobOrdId)=>(e) => {
    selectedJobOrdId = jobOrdId
    setDialogOpen(true)
  }, [])

  const memoColumns = useMemo(() => columns(handleJobOrdIdClick), [handleJobOrdIdClick])

  useEffect(() => {
    console.log("inside useEffect")
    const interval = setInterval(() => {
      if (ws?.readyState === WebSocket.OPEN) {
        ws?.send(JSON.stringify({ type: "PING" }))
      } else {
        console.log("in else ws?.readyState::", ws?.readyState)
        reConnectWebSocket(lastSelectedTHVoiceIndex)
      }
    }, 30000);

    return () => {
      console.log("return useEffect")
      ws?.close();
      clearInterval(interval)
    };
    // Close the connection when the component unmounts

  }, [ws, reConnectWebSocket]);


  return (
    <Container sx={{ height: "100%" }} maxWidth="md">
      <BoxFC height="100%" width={"100%"}>
        {/* <Button onClick={testPlaySound}>
        test
      </Button> */}
        <BoxFR>
          <Button size="small" variant="contained" onClick={connectWebSocket(selectedTHVoiceIndex)} sx={{ width: 150 }}>
            <StorageRounded sx={{ mr: 1 }} /> connect
          </Button>
          <Typography>สถานะ:</Typography><Typography fontWeight="bold" color={wsState === "open" ? green[800] : red[800]}>{wsState}</Typography>
        </BoxFR>
        <BoxFR >
          <TextField select size="small" label="เสียงภาษาไทย" sx={{ flex: 1 }}
            value={selectedTHVoiceIndex}
            onChange={handleVoiceChange}>
            {voiceTH.map((voice, index) => (
              <MenuItem key={index} value={index} >{voice.name}</MenuItem>
            ))}
          </TextField>
          <Button size="small" variant="contained" sx={{ minWidth: 0 }} onClick={testThaiSound}>
            <VolumeUp />
          </Button>
        </BoxFR>
        {/* <BoxFR>
        <CheckboxFormControl
          label="แจ้งเตือนการส่งข้อมูลตู้" checked={playJobOrderSound} onChange={e => setPlayJobOrderSound(e.target.checked)} />
        <CheckboxFormControl sx={{ ml: 2 }}
          label="แจ้งเตือน GPS" checked={playLocationSound} onChange={e => setPlayLocationSound(e.target.checked)} />
      </BoxFR> */}
        <Box flex={1} width="100%">
          <DataGridCellExpand
            hideFooter
            rows={dataTable}
            columns={memoColumns}
          />
        </Box>
      </BoxFC>
      <DailyJobDialogV2
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        selectedId={selectedJobOrdId}
      />
    </Container>
  );
}

function playAudio(sound) {
  return new Promise(res => {
    sound.play()
    sound.onended = res
  })
}

function speak(text, voice) {
  console.log("voice::", voice)
  return new Promise((resolve, reject) => {
    if (synth.speaking) {
      synth.cancel()
    }
    if (voice) {
      utterThis.lang = 'th-TH';
      utterThis.voice = voice
      utterThis.text = text
      synth.speak(utterThis);
    }
    utterThis.onend = resolve;
  });
}
export default NotificationCenter;